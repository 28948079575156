import {domainUrl} from "../../helper";

interface IDublicateProps {
    index: number;
    indexCol?: number;
    dbName: string;
    tab: number;
}
export interface IRowStylesAttr extends IDublicateProps {
    renderRowBackground: Array<Array<Array<string>>>;
    renderRowAlignItems: Array<Array<Array<string>>>;
    renderRowBgImage: Array<Array<Array<string>>>;
    renderRowBgImageHeight: Array<Array<Array<string>>>
}
export interface IColumnStylesAttr extends IDublicateProps {
    renderColumn: Array<Array<Array<{
        backgroundColor: string;
        backgroundImage: string;
        bgImageHeight: string;
        alignItems: string
    }>>>;
}

export const builderRowStylesHandler = ({
                                            index,
                                            tab,
                                            dbName,
                                            renderRowBackground,
                                            renderRowAlignItems,
                                            renderRowBgImage,
                                            renderRowBgImageHeight,
                                        }: IRowStylesAttr) => {
    if (renderRowBackground?.[0]?.[tab - 1]?.[index]
        && renderRowBackground?.[0]?.[tab - 1]?.[index] !== "transparent"
        && renderRowBackground?.[0]?.[tab - 1]?.[index] !== "none"
    ) {
        return {background: renderRowBackground?.[0]?.[tab - 1]?.[index], alignItems: renderRowAlignItems?.[0]?.[tab - 1]?.[index] ?? "flex-start"}
    } else if (renderRowBgImage?.[0]?.[tab - 1]?.[index] && renderRowBgImage?.[0]?.[tab - 1]?.[index] !== "none") {
        return {
            backgroundImage: `url(${domainUrl(`${dbName}/${renderRowBgImage?.[0]?.[tab - 1]?.[index]}`)})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            minHeight: renderRowBgImageHeight?.[0]?.[tab - 1]?.[index],
            display: "flex",
            alignItems: renderRowAlignItems?.[0]?.[tab - 1]?.[index] ?? "center"
        }
    }
    return {background: "#ffffff", alignItems: renderRowAlignItems?.[0]?.[tab - 1]?.[index] ?? "flex-start"}
}

export const builderColumnStylesHandler = ({index, indexCol, dbName, tab, renderColumn}: IColumnStylesAttr) => {
    const {
        backgroundColor: columnCol,
        backgroundImage: columnImg,
        bgImageHeight: columnHeight,
        alignItems: columnAlignment
    } = renderColumn?.[tab - 1]?.[index]?.[indexCol as number] || {}
    if (columnCol && columnCol !== "transparent" && columnCol !== "none") {
        return { background: columnCol }
    } else if (columnImg && columnImg !== "none") {
        let alignItems = {};
        if (columnAlignment && columnAlignment.length > 0) {
            alignItems = { display: "flex", alignItems: columnAlignment };
        }
        return {
            backgroundImage: `url(${domainUrl(`${dbName}/${columnImg}`)})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            minHeight: columnHeight,
            ...alignItems
        }
    }
    return {}
}