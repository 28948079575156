import {createBuilderData, idGen, renderMapData} from "../../services/utils";
import React, {FC, memo} from "react";
import cls from "./builder.module.scss"
import dynamic from "next/dynamic";
import {builderColumnStylesHandler, builderRowStylesHandler} from "./helpers";

/*** made dynamic imports for builder components ***/

const Heading = dynamic(() => import("./heading/Heading"))
const HyperLink = dynamic(() => import("./hyperLink/HyperLink"))
const Editor = dynamic(() => import("./Editor/Editor"))
const AccordionComponent = dynamic(() => import("./Accordion/Accordion"))
const ImageUploadingApp = dynamic(() => import("./ImageUploading"))
const Button = dynamic(() => import("./Button/Button"))
const VideoComponent = dynamic(() => import("./VideoComponent/VideoComponent"))
const BlogsComponent = dynamic(() => import("./BlogsComponent/BlogsComponent"))


interface IProps {
    tree: any
    tab: any
    componentsData: any
    initialLayout?: any
    domain: string;
    dbName: string
}

type TypeBuilder =
    "Heading"
    | "HyperLink"
    | "Editor"
    | "Accordion"
    | "ImageUpload"
    | "Button"
    | "Video"
    | "Blogs"

interface IRenderComponent {
    data: any
    domain: string;
    dbName?: string
}

const RenderBuilderComponent: FC<IRenderComponent> = ({data, domain, dbName}) => {
    const builderComponents = {
        Heading: <Heading item={data}/>,
        HyperLink: <HyperLink item={data}/>,
        Editor: <Editor item={data}/>,
        Accordion: <AccordionComponent item={data}/>,
        ImageUpload: <ImageUploadingApp item={data} dbName={dbName}/>,
        Button: <Button item={data}/>,
        Video: data?.fullScreen
            ? <div className="video_wrapper"><VideoComponent item={data}/></div>
            : <VideoComponent item={data}/>,
        Blogs: <BlogsComponent domain={domain} item={data}/>,
        Grid: <></>,
    }
    return builderComponents[data?.content as TypeBuilder] || <></>
}


const BuilderSection: FC<IProps> = (
    {
        tree,
        tab,
        componentsData,
        initialLayout,
        domain,
        dbName
    }) => {

    const renderClass = renderMapData(initialLayout, "class")
    const renderRowBackground = renderMapData(initialLayout, "backgroundColor")
    const renderRowBgImage = renderMapData(initialLayout, "backgroundImage")
    const renderRowBgImageHeight = renderMapData(initialLayout, "bgImageHeight")
    const renderBlockAlignItems = renderMapData(initialLayout, "alignItems")
    const renderRowAlignItems = renderMapData(initialLayout, "alignItemsRow")
    const renderRowFullScreen = renderMapData(initialLayout, "fullScreen")
    const [ renderColumn = [] ] = renderMapData(initialLayout, "column") || []
    const rowStyles = (index: number) => builderRowStylesHandler(
        {
            index,
            tab,
            dbName,
            renderRowBgImage,
            renderRowBackground,
            renderRowBgImageHeight,
            renderRowAlignItems
        })
    const columnStyles = (index: number, indexCol: number) => builderColumnStylesHandler(
        {
            index,
            indexCol,
            tab,
            dbName,
            renderColumn
        })

    return componentsData && Object.values(componentsData).length > 0 && tree[tab] && Object.values(tree[tab]).length > 0 ? (
        <div
            className="block-products"
            style={{marginBottom: 0}}
        >
            <div
                className={`${cls["home-product-container"]} ${cls["b-container"]}`}
            >
                {
                    tree && tab && Array.isArray(tree?.[tab]) && tree[tab]?.map((item: any, ind: number) => {
                        if (item?.length === 1 && item?.[0]?.length === 1) {
                            const BuilderComponent = createBuilderData(componentsData, item[0][0])
                            return (
                                <div
                                    key={idGen()}
                                    className={`zg-grid-col zg-col-lg zg-col-sm zg-col-md ${!renderRowFullScreen?.[0]?.[tab - 1]?.[ind] && "container"} ${cls.builder_component}`}
                                    style={rowStyles(ind)}
                                >
                                    <div style={{ display: "flex", flexWrap: "wrap", alignItems: renderBlockAlignItems?.[0]?.[tab - 1]?.[ind] ?? "flex-start"}}>
                                        <RenderBuilderComponent
                                            domain={domain}
                                            data={BuilderComponent}
                                            dbName={dbName}
                                        />
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div
                                    key={idGen()}
                                    className={`zg-row ${!renderRowFullScreen?.[0]?.[tab - 1]?.[ind] && "container"} ${cls.builder_component}`}
                                    style={rowStyles(ind)}
                                >
                                    <div style={{ display: "flex", flexWrap: "wrap", alignItems: renderBlockAlignItems?.[0]?.[tab - 1]?.[ind] ?? "flex-start"}}>
                                        {
                                            item?.map((element: any, index: number) => {
                                                if (element?.length === 1) {
                                                    const BuilderComponent = createBuilderData(componentsData, element[0])
                                                    return (
                                                        <div
                                                            key={idGen()}
                                                            className={`${renderClass?.[0]?.[tab - 1]?.[ind]?.[index]} ${cls.builder_component}`}
                                                        >
                                                            <RenderBuilderComponent
                                                                domain={domain}
                                                                data={BuilderComponent}
                                                                dbName={dbName}
                                                            />
                                                        </div>
                                                    )
                                                } else {
                                                    return (
                                                        <div
                                                            key={idGen()}
                                                            className={renderClass?.[0]?.[tab - 1]?.[ind]?.[index]}
                                                            style={columnStyles(ind, index)}
                                                        >
                                                            {
                                                                element?.map((elInEl: number) => {
                                                                    const BuilderComponent = createBuilderData(componentsData, elInEl)
                                                                    return (
                                                                        <div
                                                                            key={idGen()}
                                                                            className={cls.builder_component}
                                                                        >
                                                                            <RenderBuilderComponent
                                                                                domain={domain}
                                                                                data={BuilderComponent}
                                                                                dbName={dbName}
                                                                            />
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    )
                                                }
                                            })
                                        }
                                    </div>

                                </div>
                            )
                        }
                    })
                }
            </div>
        </div>
    ) : <></>
}

export default memo(BuilderSection)